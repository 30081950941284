import React from 'react'
import ButtonLink from '../ui/ButtonLink'
import * as classes from './Contact.module.css'

const Contact = () => {
 return (
     <section className={classes.container}> 
         <div className={classes.item}>
            <h4>Contact Me</h4>
            <p>Get in touch today by clicking the button below.</p>
            <ButtonLink to={'/contact'}>Get in Touch</ButtonLink>
         </div>
     </section>
 )
}

export default Contact