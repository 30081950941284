import React from "react";
import Layout from "../components/ui/Layout";
import Hero from "../components/Hero/Hero";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import FeaturedProjects from "../components/FeaturedProjects/FeaturedProjects";
import Icons from '../components/Icons/Icons'
import Mail from '../components/Mail/Mail'
import "../global/global.css";

const Index = (props) => {

  return (
    <Layout title="Home">
      <Hero />
      <About />
      <FeaturedProjects />
      <Contact />
      <Icons/>
      <Mail />
    </Layout>
  );
};

export default Index;
