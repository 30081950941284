import React from "react";
import * as classes from "./About.module.css";
import List from "../ui/List";

const LIST_DATA = [
  "Javascript",
  "HTML/CSS",
  "React",
  "Gatsby",
  "NPM",
  "Styled-Components",
  "Git",
  "ThreeJS",
  "React-Three-Fiber",
];

const About = () => {
  return (
    <section className={classes.about}>
      <div className={classes.grid}>
        <div className={classes.gridItem}>
          <h3 className="header-decoration">About me</h3>
          <p>
            Hi, my name is Barry Mulrooney. I'm a developer with a design background. 
            I like working with Front-end frameworks like React or fun libraries like THREE.js.
            I also work with vanilla Javascript and am proficient in CSS. 
          </p>
          <p>I like to write clean maintainable code.</p>
        </div>
        <div className={classes.gridItem}>
          <h3 className="header-decoration">Tech Info</h3>
          <p>Here are a few technologies I’ve worked with recently:</p>
          <List data={LIST_DATA} />
        </div>
      </div>
    </section>
  );
};

export default About;
