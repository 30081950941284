import React, { useRef  } from "react";
import { useFrame } from "react-three-fiber";
import { MeshDistortMaterial } from "@react-three/drei";

const Box3D = (props) => {
  const mesh = useRef();

  useFrame(() => {
    mesh.current.rotation.x = mesh.current.rotation.y += 0.00005;
  });

  return (
    <mesh {...props} ref={mesh} scale={props.scale}>
      <boxGeometry attach="geometry" args={[1, 4, 4, 20, 20, 20]} />
      <MeshDistortMaterial
        attach="material"
        distort={1} // Strength, 0 disables the effect (default=1)
        speed={0.25} // Speed (default=1)
        color={props.meshColor}
        wireframe={true}
      />
    </mesh>
  );
};

export default Box3D;
