import React from "react";
import { Linkedin, GitHub } from "react-feather";
import * as classes from "./Icons.module.css";

const Icons = () => {
  return (
    <div className={classes.icons}>
      <a
        className={classes.iconLink}
        href="https://github.com/mulrooneydesign"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GitHub color="#37363a" size={32} strokeWidth="1" />
      </a>
      <a
        className={classes.iconLink}
        href="https://www.linkedin.com/in/barry-diarmaid-mulrooney-391b1910/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Linkedin color="#37363a" size={32} strokeWidth="1" />
      </a>
    </div>
  );
};

export default Icons;
