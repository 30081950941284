import React from "react";
import * as classes from "./FeaturedProjects.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { GitHub, ExternalLink } from "react-feather";
import List from "../ui/List";
import ButtonLink from "../ui/ButtonLink";

const FeaturedProjects = (props) => {
  return (
    <section className={classes.container}>
      <h2 className="header-decoration">Featured Projects</h2>
      <article className={classes.project}>
        <div className={classes.info}>
          <div>
            <p className={classes.introText}>Featured Project</p>
            <h4 className={classes.title}>
              Split the Bill
            </h4>
            <p>
            Web-app built in React that allows people to easily split restaurant bills/tip.
            </p>
            <List data={["React", "Javascript", "Git", "CSS"]} />
            <h5>Links</h5>
            <a className={classes.link} href="https://github.com/mulrooneydesign/split-the-bill">
              <GitHub />
            </a>
            <a className={classes.link} href="https://pedantic-goldberg-56ea3d.netlify.app/">
              <ExternalLink />
            </a>
            <div className={classes.button}>
              <ButtonLink to={"/projects/split-the-bill/"}>
                Learn More
              </ButtonLink>
            </div>
          </div>
        </div>
        <div>
          <StaticImage
            src="../../images/FeaturedProjects/split-the-bill.png"
            alt="test"
          />
        </div>
      </article>
    </section>
  );
};

export default FeaturedProjects;
