import React, { useContext, useEffect, useState } from "react";
import { Canvas } from "react-three-fiber";
import { OrbitControls } from "@react-three/drei";
import Box3D from "./Box3D";
import * as classes from "./Hero3D.module.css";
import ThemeContext from "../../context/theme-context";

const Hero3D = () => {
  const context = useContext(ThemeContext);

  const [meshColor, setMeshColor] = useState("#37363a");

  useEffect(() => {
    if (context.isThemeSwapped === true) {
      setMeshColor("#ffffff");
    } else if (context.isThemeSwapped === false) {
      setMeshColor("#37363a");
    }
  }, [meshColor, context.isThemeSwapped]);

  return (
    <Canvas className={classes.hero3D}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Box3D meshColor={meshColor} position={[0, 0, 0]} scale={[3, 3, 3]} />
      <Box3D meshColor={meshColor} position={[0, 2, -3]} scale={[2, 2, 2]} />
      <OrbitControls />
    </Canvas>
  );
};

export default Hero3D;
