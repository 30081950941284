import React from 'react'
import * as classes from './List.module.css'
import { v4 as uuidv4 } from 'uuid';
import { ChevronRight } from 'react-feather'

const List = ({data}) => {
    return (
        <ul className={classes.list}>
            {data.map((item) => {
                return <li key={uuidv4()}><ChevronRight /> {item}</li>
            })}
        </ul>

    )
}

export default List