import React from "react";
import ButtonLink from "../ui/ButtonLink";
import * as classes from "./Hero.module.css";
import Hero3D from "../three-dimensional/Hero3D";

const Hero = (props) => {
  return (
    <>
      <section className={classes.hero}>
        <div className={classes.grid}>
          <div className={classes.gridItem}>
            <h1>I'm a Creative Developer</h1>
            <p>
              I work as a front-end developer specializing in building and
              designing state of the art digital experiences. Currently
              available for work in Barcelona where I am based.
            </p>
            <ButtonLink to={"contact"}>Hire Me</ButtonLink>
          </div>
          <div className={classes.gridItem}></div>
        </div>
      </section>
      <Hero3D />
    </>
  );
};

export default Hero;
