import React from "react";
import * as classes from "./Mail.module.css";
import { Mail } from "react-feather";

const MailComponent = () => {
  return (
    <div className={classes.mail}>
      <a className={classes.mailLink} href="mailto:barry.mulrooney@mulrooneydesign.ie" >
        <Mail color="#37363a" size={32} strokeWidth="1" />
      </a>
    </div>
  );
};

export default MailComponent;
